/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-18 18:32:15
 * @Module: 法务学院详情
 */
 <template>
  <div class="InstituteJusticeDetail">
    <el-card shadow="never" :body-style="{padding:'0px'}">
      <div slot="header" style="padding-left:24px;line-height:50px;">
        <el-button type="text" class="back" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
      </div>
      <video :src="this.$oss+data.url" controls></video>
      <div class="content">
        <h1>{{data.title}}</h1>
        <p>{{data.viewCount}}次观看 · 发布于 {{data.updatedAt|timeFormat('yyyy-mm-dd MM:ss')}} </p>
        <h5>{{data.summary}}</h5>
      </div>
    </el-card>

  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      data: {
        cover: '',
        updatedAt: 0,
        url: '',
        viewCount: 0,
        title: '',
        summary: ''
      }
    };
  },
  mounted () { },
  methods: {
    async getVideoDetail (videoId) {
      const { status, data } = await this.$api.getVideoDetail({ videoId })
      if (status === 200) {
        this.data = data;
      }
    }
  },
  watch: {
    "$route.params": {
      handler ({ id }) {
        this.getVideoDetail(id)
      },
      immediate: true
    }
  }
};
 </script>
 <style lang='scss' scoped>
.InstituteJusticeDetail {
  video {
    width: 70%;
    padding: 0 24px;
  }
  .content {
    padding: 12px 24px;
    h1 {
      color: #0c0d0f;
      line-height: 33px;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    p {
      font-size: 15px;
      line-height: 20px;
      color: #606866;
      margin-bottom: 10px;
    }
    h5 {
      font-size: 14px;
      line-height: 20px;
      text-align: justify;
      color: #606866;
      margin-bottom: 10px;
    }
  }
}
</style>